import React from "react";
import { Helmet } from "react-helmet";
import SelectionItem from '../components/common/selection-item';
import detailPagesData from '../assets/json/detail-page.json';

const Raambekleding = () => {
    const keys = Object.keys(detailPagesData);

    // TODO: find a better solution item in the json that don't need to be rendered
    keys.splice(9, 1);

    const getImage = (src) => {
        return require(`../assets/images/selection-menu/${src}.jpg`).default;
    }

    return (
        <div className={`c-raambekleding o-content-width o-fade-in`}>
            
            <Helmet>
                <title>Kathleen David Interieur | Raambekleding</title>
            </Helmet>

            {keys.map((key, index) => {
                return <SelectionItem
                    src={getImage(key)}
                    key={index}
                    link={`/raambekleding/${key}`}
                    alt={detailPagesData[key].alt}
                    title={key}
                />
            })}
        </div>
    )
}

export default Raambekleding;