import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import BackArrow from '../assets/logo/back-arrow.svg';

const DetailPage = (props) => {
    const [isHorizontal, setIsHorizontal] = useState(props.horizontal)

    const horizontalConditionRight = isHorizontal ? " c-detail-right-landscape" : "";
    const horizontalConditionLeft = isHorizontal ? " c-detail-left-landscape" : "";

    const checkWindowWidth = () => {
        if (window.innerWidth < 768) setIsHorizontal(false);
    }

    useEffect(() => {
        window.addEventListener("resize", checkWindowWidth)

        return () => {
            window.removeEventListener("resize", checkWindowWidth);
        }
    }, [])

    return (
        <div className={`c-detail o-content-width ${props.hasSecondSection ? "c-detail-mb-sm" : ""}${props.flipped ? 'c-detail-flipped' : ""}`}>

            <Helmet>
                <title>Kathleen David Interieur | {props.title}</title>
            </Helmet>

            <div className={"c-detail-left" + horizontalConditionLeft}>
                <span className="c-detail-title-container c-detail-mb">
                    {props.backArrow && (
                        <Link to="/raambekleding" className="c-detail-back-btn">
                            <img src={BackArrow} alt="Terug knop" />
                        </Link>
                    )}
                    <h2>{props.title}</h2>
                </span>
                <p className={`c-detail-mb${isHorizontal ? "-large" : ""}`}>{props.textOne}</p>
                {!isHorizontal && <p className="c-detail-mb-large">{props.textTwo}</p>}

                {props.imageLeft && <img src={props.imageLeft} alt={props.alt} className="c-detail-left-img" />}
            </div>

            <div className="c-detail-right">
                {props.imageRight && <img src={props.imageRight} alt={props.alt} className={`c-detail-right-img` + horizontalConditionRight} />}
                {isHorizontal && <p className="c-detail-mb-large">{props.textTwo}</p>}
            </div>

        </div>
    )
}

export default DetailPage;