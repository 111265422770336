import React, { useState } from 'react';
import { Radio, RadioGroup } from '@material-ui/core';

const Carousel = ({ images }) => {
    const [index, setIndex] = useState(0);
    return (
        <div id="carousel" className='c-carousel'>
            {images.map((image, key) => (
                <img alt={"Gordijn in carousel"} key={key} src={image.default} style={{ display: (key === index ? 'block' : 'none') }} />
            ))}
            <RadioGroup 
                name="image-index" 
                value={index} 
                onChange={(event) => setIndex(Number(event.target.value))} 
                row={true}
                style={{ justifyContent: 'center' }}
            >
                {images.map((image, key) => (
                    <Radio 
                        type="radio" 
                        value={key} 
                        onChange={() => setIndex(key)} 
                        color='default'
                        size="small"
                    />
                ))}
            </RadioGroup>
        </div>
    )
}

export default Carousel;
