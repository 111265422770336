import React from "react";
import { Helmet } from "react-helmet";
import Carousel from './common/carousel';

const Home = () => {

    const importAll = (r) => {
        return r.keys().map(r);
    }

    const images = importAll(require.context('../assets/images/home', false, /\.(png|jpe?g|svg)$/));

    return (
        <div className="c-home-content o-content-width-large">

            <Helmet>
                <title>Kathleen David Interieur | Home</title>
            </Helmet>

            {images && (
                <React.Fragment>
                    <Carousel images={images} />
                    <p className="c-home-text o-content-width-small">
                        Interieuradvies met oog voor detail <br />
                        Gordijnen op maat, geef je woonruimte een upgrade met de mooiste stoffen <br /> <br />
                        Kleuradvies, kies uit een trendy kleurenpalet <br />
                        Meubelstoffering, upcycling recycling <br /> <br />
                        Zonwering, originaliteit staat voorop <br /> - <br />
                        Advies aan huis 
                    </p> 
                </React.Fragment>
            )}
        </div>
    )
}

export default Home;