import React from 'react';
import { Helmet } from "react-helmet";
import Carousel from './common/carousel';

const Portfolio = () => {
    const importAll = (r) => {
        return r.keys().map(r);
    }

    const images = importAll(require.context('../assets/images/portfolio', false, /\.(png|jpe?g|svg)$/));

    return (
        <div className="c-home-content o-content-width-large">

            <Helmet>
                <title>Kathleen David Interieur | Portfolio</title>
            </Helmet>

            {images && <Carousel images={images} />}
        </div>
    )
}

export default Portfolio;