import React from "react";
import DetailPage from "./detail-page";
import detailPagesData from '../assets/json/detail-page.json';

const DetailPageWrapper = (props) => {
    window.scrollTo(0, 0);
    const key = props.match.params?.product || props.match;
    const data = detailPagesData[key];
    const dataSecondSection = data["second-section"];

    const getImage = (src) => {
        if (!src) return;
        return require(`../assets/images/detail-page/${[key]}/${src}.jpg`).default;
    }

    return (
        <React.Fragment>
            <DetailPage
                backArrow={true}
                horizontal={data.horizontal}
                title={data["title"]}
                subTitle={data["subTitle"]}
                alt={data["alt"]}
                textOne={data["text-one"]}
                textTwo={data["text-two"]}
                textThree={data["text-three"]}
                textFour={data["text-four"]}
                imageRight={getImage(data.images.right)}
                imageLeft={getImage(data.images.left)}
                hasSecondSection={dataSecondSection}
            />

            {data["second-section"] && (
                <DetailPage
                    flipped={dataSecondSection.flipped}
                    horizontal={dataSecondSection.horizontal}
                    title={dataSecondSection["title"]}
                    alt={dataSecondSection["alt"]}
                    textOne={dataSecondSection["text-one"]}
                    textTwo={dataSecondSection["text-two"]}
                    imageRight={getImage(dataSecondSection.images?.right)}
                    imageLeft={getImage(dataSecondSection.images?.left)}
                />
            )}
        </React.Fragment>

    )
}

export default DetailPageWrapper;