import React from 'react';
import { Route, Switch } from "react-router-dom";

// styles
import './styles/main.scss';

// components
import Header from './components/header';
import Footer from './components/footer';
import Home from './components/home';
import Raambekleding from "./components/raambekleding";
import DetailPageWrapper from './components/detail-page-wrapper';
import Contact from './components/contact';
import KleurAdvies from "./components/kleuradvies";
import Portfolio from './components/portfolio';
import WIP from './components/wip';

export const ApiContext = React.createContext(null);

let route = null;
const returnWorkInProgress = false;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  route = "https://localhost:44345/";
} else {
  route = "https://www.kathleendavid.be/";
}

function App() {
  return (
    <React.Fragment>
      {!returnWorkInProgress ?
        (<ApiContext.Provider value={route}>
          <div className="c-app">
            <Header></Header>
            <div className="c-body">
              <Switch>
                <Route exact path="/raambekleding/" component={Raambekleding} />
                <Route exact path="/kleuradvies" component={KleurAdvies} />
                <Route exact path="/portfolio" component={Portfolio} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/raambekleding/:product" component={DetailPageWrapper}></Route>
                <Route exact path="*" component={Home} />
              </Switch>
            </div>
            <div className="c-footer-container">
              <Footer></Footer>
            </div>
          </div>
        </ApiContext.Provider>) 
        : (<WIP />)}
    </React.Fragment>
  );
}

export default App;
