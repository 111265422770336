import React, { useState, useEffect, useContext, useRef } from 'react';
import { Helmet } from "react-helmet";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

// styling
import { makeStyles, withStyles } from '@material-ui/core/styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { ApiContext } from '../App';


const CssTextField = withStyles({
    root: {
        '& .MuiInput-underline:after': {
            borderBottomColor: 'black',
        },
        '& .MuiInput-focused': {
            borderBottomColor: 'black',
        },
        '& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: '#f44336 !important'
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: 'black'
        },
        '& .MuiFormLabel-root.Mui-error': {
            color: '#f44336 !important'
        }
    },
})(TextField);

const useStyles = makeStyles(() => ({
    customButton: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between"
    },
    customSubmit: {
        width: "auto",
        marginRight: 16
    },
    customSubmitSuccess: {
        backgroundColor: "#60d165",
        color: "white",
        '&.Mui-disabled': {
            color: "white"
        }
    },
    customSubmitError: {
        backgroundColor: "#f44336",
        color: "white",
        '&.Mui-disabled': {
            color: "white"
        }
    },
    customInput: {
        width: "100%",
        marginBottom: "1rem",
    },
    customInputName: {
        width: "45%",
        marginBottom: "1rem"
    }
}));

const Contact = () => {
    const [formData, setFormData] = useState(null);
    const [postSuccess, setPostSuccess] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [failedFetchCounter, setFailedFetchCounter] = useState(0)

    const finishedLoading = useRef(true);
    const form = useRef();

    const classes = useStyles();
    const route = useContext(ApiContext);
    const enableAppointments = false;

    const onInputChange = (event) => {
        setFormData(prevState => {
            const copy = { ...prevState };
            copy[event.target.id].value = event.target.value;
            return copy;
        })
        checkFormValues(event.target.id, event.target.value); // check the value
    }

    const removeError = (id) => {
        setFormData(prevState => {
            const copy = { ...prevState };
            copy[id].validation = null;
            return copy;
        })
    }

    const checkFormValues = (id, value) => {
        const emptyValueMessage = "Vul een waarde in";
        if (!value && formData[id].required) {
            setFormData(prevState => {
                const copy = { ...prevState }
                copy[id].validation = {}; // make an object first
                copy[id].validation.error = true;
                copy[id].validation.message = emptyValueMessage;
                return copy;
            })

            return true; // exit funtion
        }

        removeError(id); // remove error if nothing is detected
    }

    const clearForm = () => {
        setFormData(prevState => {
            const copy = { ...prevState }
            for (let item in copy) {
                copy[item].value = "";
            }
            return copy;
        })
    }

    const onFormSubmit = async () => {
        const keys = Object.keys(formData);
        let hasError = false;

        for (let key of keys) {
            const result = checkFormValues(key, formData[key].value);
            if (result) hasError = true;
        }

        if (hasError) {
            return;
        }

        // start loading after no error was found
        finishedLoading.current = false;
        setTimeout(() => {
            if (!finishedLoading.current) setIsLoading(true);
        }, 1000)

        const body = {
            firstname: formData.firstname.value,
            lastname: formData.lastname.value,
            email: formData.email.value,
            address: formData.address.value,
            phoneNumber: formData.phone.value,
            question: formData.question.value
        }

        await fetch(`${route}a/form`, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(response => {
            if (response.ok) {
                setPostSuccess(true);
                clearForm();
            } else {
                setPostSuccess(false);
            }
        }).catch(() => {
            setPostSuccess(false);
            // update the counter
            setFailedFetchCounter(failedFetchCounter + 1);
        })

        // stop loading
        finishedLoading.current = true;
        setIsLoading(false);
    }

    const addFormSettings = () => {
        const inputs = {
            firstname: { value: "", required: true, validation: null, },
            lastname: { value: "", required: true, validation: null, },
            email: { value: "", required: true, validation: null, },
            phone: { value: "", required: true, validation: null, },
            address: { value: "", required: true, validation: null, },
            question: { value: "", required: true, validation: null, }
        };
        setFormData(inputs);
    }

    useEffect(() => {
        addFormSettings();
    }, [])

    useEffect(() => {
        if (postSuccess !== null) {
            setTimeout(() => {
                setPostSuccess(null);
            }, 4000)
        }
    }, [postSuccess])

    const RequestInfoComponent = (props) => {
        setTimeout(() => setFailedFetchCounter(0), 7500);
        return (
            <div className="c-contact-error-text">{props.text}</div>
        )
    }

    return (
        <div className="c-contact o-content-width-small">
                        
            <Helmet>
                <title>Kathleen David Interieur | Contact</title>
            </Helmet>

            <div className="c-contact-body">
                <h2 className="c-contact-title">Contact</h2>
                {enableAppointments && (
                    <React.Fragment>
                        <div className="c-contact-appointments">
                            <p>
                                Maak een afspraak online via de agenda.
                                Dan zal ik bij uw thuis komen om zaken te bespreken.
                                Dit is enkel beschikbaar voor klanten in de regio West-Vlaanderen.
                            </p>
                            <Button variant="outlined" endIcon={<NavigateNextIcon />} className={classes.customButton}>Afspraak</Button>
                        </div>
                        <h3 className="c-contact-subtitle">Stel een vraag</h3>
                    </React.Fragment>
                )}

                {formData && (
                    <form autoComplete="off" ref={form}>
                        <div className="c-contact-form-inputs">
                            {/* Left side of the form */}
                            <div className="c-contact-form-inputs-left">
                                <div className="c-contact-person-name">
                                    <CssTextField
                                        id="firstname"
                                        label="voornaam"
                                        required={formData.firstname.required}
                                        value={formData.firstname.value}
                                        className={classes.customInputName}
                                        onChange={e => onInputChange(e)}
                                        error={formData.firstname.validation?.error || false}
                                        helperText={formData.firstname.validation?.message || ""}
                                    />
                                    <CssTextField
                                        id="lastname"
                                        label="naam"
                                        required={formData.lastname.required}
                                        value={formData.lastname.value}
                                        className={classes.customInputName}
                                        onChange={e => onInputChange(e)}
                                        error={formData.lastname.validation?.error || false}
                                        helperText={formData.lastname.validation?.message || ""}
                                    />
                                </div>
                                <CssTextField
                                    id="email"
                                    label="e-mail"
                                    type="email"
                                    required={formData.email.required}
                                    value={formData.email.value}
                                    className={classes.customInput}
                                    onChange={e => onInputChange(e)}
                                    error={formData.email.validation?.error || false}
                                    helperText={formData.email.validation?.message || ""}
                                />
                                <CssTextField
                                    id="phone"
                                    label="telefoonnummer"
                                    type="tel"
                                    required={formData.phone.required}
                                    value={formData.phone.value}
                                    className={classes.customInput}
                                    onChange={e => onInputChange(e)}
                                    error={formData.phone.validation?.error || false}
                                    helperText={formData.phone.validation?.message || ""}
                                />
                                <CssTextField
                                    id="address"
                                    label="adres"
                                    required={formData.address.required}
                                    value={formData.address.value}
                                    className={classes.customInput}
                                    onChange={e => onInputChange(e)}
                                    error={formData.address.validation?.error || false}
                                    helperText={formData.address.validation?.message || ""}
                                />
                            </div>

                            {/* Right side of the form */}
                            <div className="c-contact-form-inputs-right">
                                <CssTextField
                                    id="question"
                                    label="uw vraag"
                                    multiline
                                    required={formData.question.required}
                                    maxRows={10}
                                    value={formData.question.value}
                                    className={classes.customInput}
                                    onChange={e => onInputChange(e)}
                                    error={formData.question.validation?.error || false}
                                    helperText={formData.question.validation?.message || ""}
                                />
                            </div>
                        </div>
                        <div className="c-contact-button-container">
                            <Button
                                variant="outlined"
                                className={`${classes.customSubmit} c-contact-button-container-submit ${postSuccess !== null ? postSuccess ? classes.customSubmitSuccess : classes.customSubmitError : ""}`}
                                onClick={onFormSubmit}
                                disabled={postSuccess !== null}
                            >
                                {postSuccess !== null ? postSuccess ? "Gelukt" : "Niet verzonden" : "Verzenden"}
                            </Button>
                            {isLoading && <CircularProgress size="24px" />}
                        </div>
                        {postSuccess && <RequestInfoComponent text={'U ontvangt een mail met bevestiging. Indien deze niet te vinden is bekijk de spam folder.'}/>}
                        {failedFetchCounter > 3 && <RequestInfoComponent text={'Mogelijks verzendt u teveel het formulier. Probeer het later opnieuw.'}/>}
                    </form>
                )}

            </div>
        </div>
    )
}

export default Contact;