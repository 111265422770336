import React from "react";
import FacebookIcon from '../assets/socials/fb.svg';
import InstaIcon from '../assets/socials/insta.svg';
import PinterestIcon from '../assets/socials/pinterest.svg'

const Footer = () => {
    return (
        <div className="c-footer">
            <div className="c-footer-content o-content-width">
                <p>0468 41 47 38</p>
                <p>info@kathleendavid.be</p>
                <div className="c-footer-socials c-footer__item">
                    <a href="https://www.facebook.com/kathleendavidinterieur">
                        <img className="c-footer-socials__item" src={FacebookIcon} alt="Facebook Logo" />
                    </a>
                    <a href="https://instagram.com/kathleendavidinterieur">
                        <img className="c-footer-socials__item" src={InstaIcon} alt="Instagram Logo" />
                    </a>
                    <a href="https://www.pinterest.com/kathleendavidinterieur/">
                        <img className="c-footer-socials__item" src={PinterestIcon} alt="Pinterest Logo" />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Footer;