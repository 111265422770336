import React from 'react';
import { Helmet } from "react-helmet";
import DetailPage from './detail-page';

const KleurAdvies = () => {
    const dataKleurAdvies = {
        textOne: "Wenst u uw persoonlijke smaak volledig tot uiting te brengen? Dan staan wij u graag bij voor een professioneel kleuradvies! Dit afgaande op uw eigen smaak, de doelgerichtheid en lichtinval van uw ruimtes.",
        textTwo: "Graag stellen wij een kleurenpalet samen die aan al uw wensen voldoen. U krijgt van ons in optima forma advies over de verschillende kleuren bijpassend bij uw interieur.",
        images: {
            left: "kla-1",
            right: "kla-2"
        }
    }

    const dataMeubelStoffering = {
        textOne: "Hebt u een meubel in huis die niet meer eigentijds is? Dan is meubelstoffering een ideale en creatieve oplossing voor u! U kan uw meubels in een eigentijds jasje stoppen, dit door middel van stoffering in allerlei materialen en kleuren.",
        textTwo: "U bent hierbij niet alleen functioneel, maar hierbij zet u ook een ecologische voetafdruk op onze planeet.",
        images: {
            left: "gnw-1",
            right: "gnw-2"
        }
    }

    const getImage = (src) => {
        return require(`../assets/images/detail-page/kleuradvies/${src}.jpg`).default;
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Kathleen David Interieur | Kleuradvies</title>
            </Helmet>

            <DetailPage
                title={"Kleuradvies"}
                alt={"Kleuradvies"}
                horizontal={false}
                textOne={dataKleurAdvies.textOne}
                textTwo={dataKleurAdvies.textTwo}
                imageLeft={getImage(dataKleurAdvies.images.left)}
                imageRight={getImage(dataKleurAdvies.images.right)}
                hasSecondSection={true}
            />
            <DetailPage
                title={"Meubelstoffering"}
                alt={"Meubelstoffering"}
                horizontal={false}
                flipped={true}
                textOne={dataMeubelStoffering.textOne}
                textTwo={dataMeubelStoffering.textTwo}
                imageLeft={getImage(dataMeubelStoffering.images.left)}
                imageRight={getImage(dataMeubelStoffering.images.right)}
            />
        </React.Fragment>
    )
}

export default KleurAdvies;