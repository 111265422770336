import React, { useState } from "react";
import Logo from '../assets/logo/logo-initials-md.jpg';
import Menu from '../assets/logo/menu.svg';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    customButton: {
        width: 280,
        height: 48,
        display: "flex",
        justifyContent: "space-between"
    }
}));

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const classes = useStyles();

    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setMenuOpen(open);
    };


    return (
        <React.Fragment>
            <SwipeableDrawer
                anchor={"left"}
                open={menuOpen}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                <div className="c-header-popup-menu">
                    <div className="c-header-popup-menu-section">
                        <h2 className="c-header-popup-menu-title">Menu</h2>
                    </div>
                    <Link to="/raambekleding" onClick={toggleDrawer(false)}><Button color="default" className={classes.customButton}>Raambekleding</Button></Link>
                    <Link to="/kleuradvies" onClick={toggleDrawer(false)}><Button color="default" className={classes.customButton}>Kleuradvies</Button></Link>
                    <Link to="/portfolio" onClick={toggleDrawer(false)}><Button color="default" className={classes.customButton}>Portfolio</Button></Link>
                    <Link to="/contact" onClick={toggleDrawer(false)}><Button color="default" className={classes.customButton}>Contact</Button></Link>
                    <Link to="/" onClick={toggleDrawer(false)}><Button color="default" className={classes.customButton}>Home</Button></Link>
                </div>
            </SwipeableDrawer>

            <div className="c-header">
                <div className="c-header-content-mobile">
                    <img
                        className="c-header-logo c-header-menu"
                        src={Menu}
                        alt="Kathleen David Interieur logo"
                        onClick={toggleDrawer(true)}
                    />
                    <Link to="/">
                        <img className="c-header-logo" src={Logo} alt="Kathleen David Interieur logo" />
                    </Link>
                </div>
                <div className="c-header-content o-content-width">
                    <Link to="/">
                        <img className="c-header-logo" src={Logo} alt="Kathleen David Interieur logo" />
                    </Link>
                    <div className="c-header-links">
                        <Link to="/raambekleding">Raambekleding</Link>
                        <Link to="/kleuradvies">Kleuradvies</Link>
                        <Link to="/portfolio">Portfolio</Link>
                        <Link to="/contact">Contact</Link>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Header;