import React from "react";
import { Link } from 'react-router-dom';

const SelectionItem = ({ src, alt, link, title }) => {
    return (
        <Link to={link}>
            <div className="c-raambekleding-details">
                <p>{title}</p>
            </div>
            <img className="c-raambekleding-image" src={src} alt={alt} />
        </Link>
    )
}

export default SelectionItem;